<template>
  <div>
    <appheader index="3"></appheader>
    <div class="list">
      <div class="list_title">日志管理</div>
      <div class="search">
        <div class="demo-input-suffix">
          <div class="account">账号：</div>
          <el-input placeholder="输入账号查询" prefix-icon="el-icon-search" v-model="userCode"> </el-input>
        </div>
        <el-button @click="serarch">搜 索</el-button>
      </div>
      <div class="tab">
        <el-table
          :data="tableData"
          v-loading="loading"
          element-loading-text="正在加载数据"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0,.1)"
          border
          style="width: 100%"
        >
          <el-table-column prop="userCode" label="账号"> </el-table-column>
          <el-table-column prop="time" label="操作时间"> </el-table-column>
          <el-table-column prop="remark" label="备注"> </el-table-column>
        </el-table>
      </div>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          background
          :page-sizes="[10, 20, 40, 60, 100]"
          :page-size="pageSize"
          layout="total, prev, pager, next, sizes, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import Appheader from '../moudel/Appheader.vue';
import bottom from '../bady_bottom/bottom';
export default {
  name: 'Logmanagement',
  data() {
    return {
      loading: false,
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      userCode: '',
    };
  },
  components: {
    Appheader,
    bottom,
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.getList();
  },
  methods: {
    serarch() {
      this.pageNum = 1;
      this.getList();
    },
    getList() {
      this.tableData = [];
      this.loading = true;
      var url = `/system/log/list`;
      this.$axios({
        url: url,
        method: 'post',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          userCode: this.userCode,
        }),
      }).then((res) => {
        this.total = res.data.total;
        if (res.data.code == 0) {
          res.data.rows.forEach((element) => {
            this.tableData.push(element);
          });
          this.loading = false;
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
  },
};
</script>
<style scoped lang="scss">
.list {
  width: 1500px;
  margin: 0 auto;
  min-height: 550px;
}
.list_title {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  margin: 28px 0;
}
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.demo-input-suffix {
  display: flex;
  align-content: center;
  align-items: center;
}
.account {
  width: 60px;
}
.el-button:hover {
  border-radius: none;
  border: 1px solid #ec9368;
}
.el-button {
  width: 150px;
  padding: 0;
  color: #fff;
  background: #ec9368;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  border-radius: none;
}
.tab {
  margin: 20px 0 0 0;
}
.page {
  text-align: center;
  margin: 40px 0;
}
/deep/ .el-table th,
.el-table tr {
  background: #ec9368;
  color: #fff;
}
.el-pagination.is-background .btn-next.disabled,
.el-pagination.is-background .btn-next:disabled,
.el-pagination.is-background .btn-prev.disabled,
.el-pagination.is-background .btn-prev:disabled,
.el-pagination.is-background .el-pager li.disabled {
  color: #c0c4cc !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ec9368 !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  color: #fff !important;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:hover {
  color: #ec9368 !important;
}
/deep/ .el-pager li.active + li {
  border: none !important;
}
</style>
